import { CheckIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { Flex, FlexProps } from '../flex';
import { inputWrapperClassName } from '../input';
import { Text } from '../text';
import { countryCollection } from '@/lib/constants';
import FlagComponent from '../flag';
import DownArrowSVG from '@/ui/svgs/down-arrow-svg';
import { CountryCode } from '@/shared/types';

export type CountrySelectOption = CountryCode;

export type CountrySelectProps = Omit<FlexProps, 'onChange'> & {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  /** Don't show selected country's name */
  disableName?: boolean;
  /** Don't show selected country's calling code */
  disableCallingCode?: boolean;
  disablePlaceholder?: boolean;
  enableDownArrow?: boolean;
  options: CountrySelectOption[];
  onChange: (countryCode: CountryCode) => void;
};

export function CountrySelect({
  value,
  options,
  onChange,
  className,
  placeholder,
  disabled = false,
  disableName = false,
  disableCallingCode = false,
  disablePlaceholder = false,
  enableDownArrow = false,
  ...props
}: CountrySelectProps) {
  const selectedCountry = countryCollection[(value as CountryCode) || 'GB'];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Flex
          {...props}
          as="button"
          align="center"
          gap="units-unit4"
          className={cn(inputWrapperClassName, className)}
        >
          {value ? (
            <Flex className="w-full" justify="between">
              <Flex gapX="units-unit8">
                <FlagComponent countryCode={value} disabled={disabled} />
                {(!disableName || !disableCallingCode) && (
                  <Text
                    weight="medium"
                    variant="body-default"
                    color={
                      disabled
                        ? 'content-label-default'
                        : 'content-body-default'
                    }
                    className="flex gap-units-unit4"
                  >
                    {disableName || (
                      <span className="truncate font-semibold">
                        {selectedCountry.name}
                      </span>
                    )}
                    {disableCallingCode || (
                      <span className="flex-shrink-0">
                        +{selectedCountry.callingCode}
                      </span>
                    )}
                  </Text>
                )}
              </Flex>
              {enableDownArrow && <DownArrowSVG />}
            </Flex>
          ) : disablePlaceholder ? (
            ''
          ) : (
            <Text variant="body-small" color="content-label-alternative">
              {placeholder || 'Select country'}
            </Text>
          )}
        </Flex>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {options.map((option) => {
          const country = countryCollection[option as CountryCode] || {
            name: '',
            flagCode: '',
            callingCode: '',
          };

          return (
            <DropdownMenuItem key={option} onSelect={() => onChange(option)}>
              <FlagComponent countryCode={option} />
              <span className="flex-1 text-sm">{country.name}</span>
              {option && (
                <span className="text-foreground/50 text-sm">
                  {`+${country.callingCode}`}
                </span>
              )}
              <CheckIcon
                className={cn(
                  'ml-auto h-4 w-4',
                  option === value ? 'opacity-100' : 'opacity-0',
                )}
              />
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default CountrySelect;
