import { Info } from 'lucide-react';

import { Flex, FlexProps } from '../flex';
import { Text, TextProps } from '../text';

export type TextFieldHelperTextProps = TextProps &
  FlexProps & {
    disableIcon?: boolean;
    renderIcon?: (props: {
      size: number;
      className?: string;
    }) => React.ReactNode;
  };

export function TextFieldHelperText({
  id,
  children,
  renderIcon,
  disableIcon = false,
  ...props
}: TextFieldHelperTextProps) {
  return (
    <Text
      as={Flex}
      align="start"
      weight="medium"
      className="mt-2"
      gap="units-unit4"
      variant="body-small"
      {...props}
    >
      {renderIcon
        ? renderIcon({ size: 12, className: 'mt-0.5' })
        : !disableIcon && <Info size={12} className="mt-0.5 flex-shrink-0" />}
      {children}
    </Text>
  );
}
