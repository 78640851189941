import { ComponentPropsWithoutRef } from 'react';

const NGFlag = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      {...props}
      fill="none"
      role="img"
      viewBox="0 0 24 24"
      aria-label="Nigeria Flag"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_693)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="white"
        />
        <path
          d="M0 12C0 17.1596 3.2565 21.5581 7.82611 23.2537V0.746445C3.2565 2.44191 0 6.84047 0 12Z"
          fill="#0F7037"
        />
        <path
          d="M24 12C24 6.84047 20.7435 2.44191 16.1739 0.746445V23.2537C20.7435 21.5581 24 17.1596 24 12Z"
          fill="#0F7037"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_693">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NGFlag;
