const GBFlag = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      {...props}
      fill="none"
      role="img"
      viewBox="0 0 24 25"
      aria-label="Great Britain Flag"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_1656)">
        <path
          d="M12 24.9092C18.6274 24.9092 24 19.5366 24 12.9092C24 6.28176 18.6274 0.90918 12 0.90918C5.37258 0.90918 0 6.28176 0 12.9092C0 19.5366 5.37258 24.9092 12 24.9092Z"
          fill="white"
        />
        <path
          d="M2.48032 5.60352C1.53771 6.82991 0.826945 8.24342 0.413086 9.779H6.6558L2.48032 5.60352Z"
          fill="#01226A"
        />
        <path
          d="M23.5863 9.779C23.1725 8.24347 22.4616 6.82995 21.5191 5.60356L17.3437 9.779H23.5863Z"
          fill="#01226A"
        />
        <path
          d="M0.413086 16.0399C0.826992 17.5754 1.53776 18.9889 2.48032 20.2152L6.65566 16.0399H0.413086Z"
          fill="#01226A"
        />
        <path
          d="M19.3055 3.39004C18.0791 2.44743 16.6657 1.73666 15.1301 1.32275V7.56543L19.3055 3.39004Z"
          fill="#01226A"
        />
        <path
          d="M4.69434 22.4288C5.92073 23.3714 7.33424 24.0821 8.86977 24.496V18.2534L4.69434 22.4288Z"
          fill="#01226A"
        />
        <path
          d="M8.86973 1.32275C7.3342 1.73666 5.92068 2.44743 4.69434 3.38999L8.86973 7.56538V1.32275Z"
          fill="#01226A"
        />
        <path
          d="M15.1299 24.496C16.6654 24.0821 18.0789 23.3714 19.3053 22.4288L15.1299 18.2534V24.496Z"
          fill="#01226A"
        />
        <path
          d="M17.3437 16.0399L21.5191 20.2153C22.4616 18.9889 23.1725 17.5754 23.5863 16.0399H17.3437Z"
          fill="#01226A"
        />
        <path
          d="M23.8984 11.344H13.5653H13.5653V1.01076C13.0529 0.944055 12.5305 0.90918 12 0.90918C11.4694 0.90918 10.9471 0.944055 10.4348 1.01076V11.3439V11.3439H0.101578C0.034875 11.8563 0 12.3787 0 12.9092C0 13.4398 0.034875 13.962 0.101578 14.4744H10.4347H10.4347V24.8076C10.9471 24.8743 11.4694 24.9092 12 24.9092C12.5305 24.9092 13.0529 24.8744 13.5652 24.8076V14.4745V14.4744H23.8984C23.9651 13.962 24 13.4398 24 12.9092C24 12.3787 23.9651 11.8563 23.8984 11.344Z"
          fill="#CF3032"
        />
        <path
          d="M15.1309 16.0396L20.4857 21.3944C20.732 21.1482 20.9669 20.8908 21.191 20.624L16.6066 16.0396H15.1309V16.0396Z"
          fill="#CF3032"
        />
        <path
          d="M8.86954 16.0397H8.86944L3.51468 21.3944C3.76087 21.6407 4.01826 21.8757 4.28507 22.0998L8.86954 17.5153V16.0397Z"
          fill="#CF3032"
        />
        <path
          d="M8.86954 9.77883V9.77873L3.51473 4.42387C3.26845 4.67006 3.03351 4.92745 2.80936 5.19426L7.39387 9.77878H8.86954V9.77883Z"
          fill="#CF3032"
        />
        <path
          d="M15.1309 9.77899L20.4857 4.42408C20.2395 4.1778 19.9821 3.94286 19.7153 3.71875L15.1309 8.30327V9.77899Z"
          fill="#CF3032"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1656">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.90918)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GBFlag;
