import { SVGProps } from 'react';

export const NigeriaFlagRound = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9263_8800)">
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="white"
        />
        <path
          d="M0 10C0 14.2997 2.71375 17.9651 6.52176 19.3781V0.62207C2.71375 2.03496 0 5.70043 0 10Z"
          fill="#0F7037"
        />
        <path
          d="M19.9998 10C19.9998 5.70043 17.286 2.03496 13.478 0.62207V19.3781C17.286 17.9651 19.9998 14.2997 19.9998 10Z"
          fill="#0F7037"
        />
      </g>
      <defs>
        <clipPath id="clip0_9263_8800">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
