import { cn } from '@/lib/utils';

import flags from './flags';

// Assuming you have the flags component for each country

interface FlagComponentProps {
  disabled?: boolean;
  className?: string;
  countryCode: string;
}

const FlagComponent: React.FC<FlagComponentProps> = ({
  disabled,
  className,
  countryCode,
}) => {
  const selectedFlag = flags.find((flag) => flag.countryCode === countryCode);

  if (!selectedFlag) {
    return <div aria-hidden="true" className={cn('size-5', className)} />;
  }

  return (
    <span
      aria-hidden="true"
      className={cn(
        'bg-foreground/20 flex size-5 overflow-hidden rounded-sm',
        { 'opacity-40': disabled },
        className,
      )}
    >
      <selectedFlag.icon />
    </span>
  );
};

export default FlagComponent;
