import { Button } from '../button';
import { Row } from '../flex';
import { Text } from '../text';
import { useState } from 'react';
import CountrySelect from './country-select';

export function CountrySelectDemo() {
  const [value, setValue] = useState('');
  const [disableName, setDisableName] = useState(false);
  const [disablePlaceholder, setDisablePlaceholder] = useState(false);
  const [disableCallingCode, setDisableCallingCode] = useState(false);

  return (
    <>
      <Text variant="header-default">Country select</Text>
      <Row gap="units-unit4">
        <Button size="small" appearance="plain" onClick={() => setValue('')}>
          Reset
        </Button>
        <Button
          size="small"
          appearance="plain"
          onClick={() => setDisableName((prev) => !prev)}
        >
          Toggle name
        </Button>
        <Button
          size="small"
          appearance="plain"
          onClick={() => setDisablePlaceholder((prev) => !prev)}
        >
          Toggle placeholder
        </Button>
        <Button
          size="small"
          appearance="plain"
          onClick={() => setDisableCallingCode((prev) => !prev)}
        >
          Toggle calling code
        </Button>
      </Row>
      <CountrySelect
        value={value}
        options={['GB']}
        onChange={(value) => setValue(value)}
        {...{ disableName, disablePlaceholder, disableCallingCode }}
      />
    </>
  );
}
