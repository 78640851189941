import { PhoneInfoValue } from '@/features/profile/types';

import { CountryCode } from '@/shared/types';

export const countryOptionsForPhoneInput: CountryCode[] = ['GB'];

export const phoneInputPatternsByCountryCode: Record<number, RegExp> = {
  44: /^(?:7\d{9}|[1-6]\d{9}|8[0-8]\d{8}|9[0-9]\d{8})$/, // UK
};

export const phoneValidationRules = {
  validate: {
    countryCodeRequired: ({ countryCode }: PhoneInfoValue) =>
      !!countryCode || 'Country code is required',
    phoneNumberRequired: ({ number }: PhoneInfoValue) =>
      !!number || 'Phone number is required',
    validPhoneNumber: ({ countryCode, number }: PhoneInfoValue) =>
      phoneInputPatternsByCountryCode[+countryCode]?.test(number) ||
      `Please enter a valid phone number.`,
  },
};
