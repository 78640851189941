import { SVGProps } from 'react';

export const GBFlagRound = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_9231_19639)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="white"
        />
        <path
          d="M2.48032 4.69434C1.53771 5.92073 0.826945 7.33424 0.413086 8.86982H6.6558L2.48032 4.69434Z"
          fill="#01226A"
        />
        <path
          d="M23.5864 8.86977C23.1725 7.33424 22.4617 5.92073 21.5191 4.69434L17.3438 8.86977H23.5864Z"
          fill="#01226A"
        />
        <path
          d="M0.413086 15.1306C0.826992 16.6661 1.53776 18.0797 2.48032 19.306L6.65566 15.1306H0.413086Z"
          fill="#01226A"
        />
        <path
          d="M19.3053 2.48086C18.0789 1.53825 16.6655 0.82748 15.1299 0.413574V6.65625L19.3053 2.48086Z"
          fill="#01226A"
        />
        <path
          d="M4.69434 21.5196C5.92073 22.4622 7.33424 23.173 8.86977 23.5869V17.3442L4.69434 21.5196Z"
          fill="#01226A"
        />
        <path
          d="M8.86973 0.413574C7.3342 0.82748 5.92068 1.53825 4.69434 2.48081L8.86973 6.6562V0.413574Z"
          fill="#01226A"
        />
        <path
          d="M15.1299 23.5869C16.6654 23.173 18.0789 22.4622 19.3053 21.5196L15.1299 17.3442V23.5869Z"
          fill="#01226A"
        />
        <path
          d="M17.3438 15.1306L21.5191 19.3061C22.4617 18.0797 23.1725 16.6661 23.5864 15.1306H17.3438Z"
          fill="#01226A"
        />
        <path
          d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
          fill="#CF3032"
        />
        <path
          d="M15.1309 15.1304L20.4857 20.4852C20.732 20.239 20.9669 19.9817 21.191 19.7148L16.6066 15.1304H15.1309V15.1304Z"
          fill="#CF3032"
        />
        <path
          d="M8.86951 15.1306H8.86941L3.51465 20.4854C3.76084 20.7317 4.01823 20.9666 4.28504 21.1908L8.86951 16.6062V15.1306Z"
          fill="#CF3032"
        />
        <path
          d="M8.86976 8.8696V8.86951L3.51495 3.51465C3.26866 3.76084 3.03373 4.01823 2.80957 4.28504L7.39409 8.86955H8.86976V8.8696Z"
          fill="#CF3032"
        />
        <path
          d="M15.1309 8.86981L20.4857 3.5149C20.2395 3.26862 19.9821 3.03368 19.7153 2.80957L15.1309 7.39409V8.86981Z"
          fill="#CF3032"
        />
      </g>
      <defs>
        <clipPath id="clip0_9231_19639">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
