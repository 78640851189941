import { isUndefined } from 'lodash';
import * as React from 'react';

import { toNonInteractiveElement } from '@/ui/system';

import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  endAdornment?: React.ReactNode;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  startAdornment?: React.ReactNode;
  renderInput?: (props: InputProps) => React.ReactNode;
  mask?: (value: unknown) => string;
  maskedClassName?: string;
}

const inputWrapperClassName =
  'group flex h-10 items-center gap-2 rounded-md border-[1px] border-slate-100 bg-white px-3 disabled:opacity-50';

const inputClassName =
  'disabled:text-content-labels h-full w-0 flex-grow bg-transparent text-body-default placeholder:text-body-default file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus:outline-none disabled:cursor-not-allowed';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      mask,
      value,
      error = false,
      disabled = false,
      className,
      maskedClassName,
      wrapperProps,
      endAdornment,
      startAdornment,
      renderInput,
      role = 'textbox',
      ...props
    },
    ref,
  ) => {
    const inputProps = {
      ...props,
      ref,
      disabled,
      value,
      className: cn(inputClassName, className),
    };

    const MaskedElement =
      !!mask &&
      toNonInteractiveElement(
        <span
          className={cn(
            'absolute overflow-hidden flex select-none tracking-normal z-0',
            inputProps?.className,
            'h-auto',
            maskedClassName,
          )}
        >
          {value ? mask(value) : ''}
        </span>,
      );

    return (
      <div
        {...wrapperProps}
        className={cn(
          inputWrapperClassName,
          'relative',
          {
            'border-feedback-danger-filled-default': error,
            'cursor-not-allowed bg-ui-input-disabled': disabled,
            'focus-within:border-feedback-information-filled-default':
              !disabled,
          },
          wrapperProps?.className,
        )}
      >
        {startAdornment}
        {renderInput ? (
          renderInput(inputProps)
        ) : (
          <>
            <input
              role={role}
              {...inputProps}
              className={cn(inputProps.className, {
                'masked-text': !!mask && !isUndefined(value),
              })}
            />
            {MaskedElement}
          </>
        )}
        {endAdornment}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { inputWrapperClassName, inputClassName, Input };
